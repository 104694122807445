.header {
  width: 100%;
  background: url("../../../assets/images/hero.png");
  background-size: cover;
  background-position: top;
  padding: 2rem 10rem;
  padding-bottom: 20rem;
  color: #F8F8FC;
  /* height: 100vh; */
}
.header > div {
  width: 55%;
  padding-top: 7rem;
}
.header h1 {
  font-weight: 800;
  font-size: 9rem;
  line-height: 9.6rem;
  text-transform: capitalize; 
  margin-bottom: 2rem;
}
.header p {
  font-size: 1.8rem;
  line-height: 2.8rem;
  margin-bottom: 3rem;
}
.get-started-dark {
  background: #1C1C1E;
  color: #fff;
}
.get-started-dark:hover {
  background: transparent;
  color: #1C1C1E;
  border: 1px solid #1C1C1E;
}

@media screen and (max-width: 768px) {
  .header {
    background: linear-gradient(71.72deg, #AE207C 0%, #E20E17 56.13%, #F7941F 109.97%);
    padding: 4rem 0;
    padding-bottom: 0;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .header nav,
  .header > div {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .header > div {
    width: 100%;
  }
  .header h1 {
    font-size: 4.9rem;
    line-height: 6.4rem;
  }
  .get-started-dark {
    margin-bottom: 3rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .header > div {
    width: 60%;
  }
  .header h1 {
    font-size: 7rem;
    line-height: 8.5rem;
  }
}
.step-instruction h4 {
  font-weight: 500;
  font-size: 6rem;
  letter-spacing: -0.02em;
  color: #1C1C1E;
  margin-bottom: 1rem;
}
.step-instruction p {
  line-height: 3.1rem;
  color: #3A3A3C;
  mix-blend-mode: normal;
}

@media screen and (max-width: 768px) {
  .step-instruction h4 {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
}
.instructions-wrapper {
  padding: 6rem 20rem;
}
.instructions-wrapper > h4 {
  with: 63%;
  margin: auto;
  padding-top: 4rem;
  text-align: center;
  font-weight: 100;
  font-size: 22px;
  line-height: 32px;
}
.instructions-wrapper > h4 > a {
  color: #FF1C26;
}
.instructions-wrapper > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .instructions-wrapper {
    padding: 6rem 3rem;
    padding-top: 32rem;
  }
  .instructions-wrapper > h4 {
    padding-top: 2rem;
    padding-bottom: 6rem;
  }
  .instructions-wrapper > div {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 4.5rem;
  }
  .instructions-wrapper > div:nth-child(3) {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .instructions-wrapper {
    padding: 6rem 10rem;
  }
}
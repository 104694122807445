.btn-start-transparent {
  color: #fff;
  border: 1px solid #EBEBF0;
}
.transparent {
  background: none;
  border: 0;
}
.menu-wrapper {
  background: #fff;
  padding: 4rem 2rem;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 75%;
}
.footer {
  background: #000;
  padding: 6rem 10rem;
  padding-bottom: 10rem;
}
.footer p {
  font-size: 1.7rem;
  line-height: 146%;
  text-align: justify;
  color: #FFF;
  opacity: 0.5;
}
.footer .links a {
  display: inline-block;
  font-size: 1.8rem;
  line-height: 135%;
  color: #FFF;
  text-decoration: none;
  padding-right: 3.5rem;
  text-transform: capitalize;
}
.footer hr {
  border: 0;
  border-top: 0.5px solid rgba(255, 255, 255, 0.25);
  margin: 3rem 0;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 7rem 2rem;
    padding-bottom: 6.5rem;
  }
  .footer > div {
    display: block;
  }
  .footer .links,
  .footer .social-links {
    text-align: center;
  }
  .footer .links a {
    display: block;
    padding-right: 0;
    padding-bottom: 4.5rem;
  }
  .footer hr {
    margin-top: 4.5rem;
  }
}
.prefooter {
  position: relative;
  padding: 6rem;
  text-align: center;
  color: #1C1C1E;
}
.prefooter h4 {
  font-weight: 500;
  font-size: 6rem;
  line-height: 9rem;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}
.prefooter p {
  width: 45%;
  margin: auto;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  line-height: 2.8rem;
  mix-blend-mode: normal;
}
.prefooter > img {
  position: absolute;
}
.prefooter .star {
  right: 22rem;
  top: 4.5rem;
}
.prefooter .vector {
  left: 22rem;
  bottom: 13.5rem;
}
.prefooter > div > a > img {
  margin: 0 5px;
}
@media screen and (max-width: 768px) {
  .prefooter {
    padding: 6rem 2rem;
  }
  .prefooter h4 {
    font-size: 4.1rem;
    line-height: 4.7rem;
  }
  .prefooter p {
    width: 100%;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .prefooter h4 {
    font-size: 5rem;
  }
  .prefooter .star {
    top: 2.5rem;
  }
}
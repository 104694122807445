.features-wrapper {
  background: #1C1C1E;
  padding: 10rem 15rem;
  border-top: 13px solid;
  border-image: linear-gradient(to right, #1C1C1E 10%, #FFCC00 10%, #FFCC00 50%, #1c1c1e 50%, #1c1c1e 65%, #F2392B 65%, #F2392B 100%) 5;
  transform: skewY(3deg);
  margin-top: -3.6rem;
}
.features-wrapper h2,
.features-wrapper > div,
.features-wrapper hr {
  transform: skewY(-3deg);
}
.features-wrapper h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 6rem;
  line-height: 6.5rem;
  letter-spacing: -0.02em;
  color: #EBEBF0;
  text-align: center;
}
.features-wrapper .list-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 2.5rem;
}
.features-wrapper hr {
  border: 0;
  border-top: 1px solid #3A3A3C;
  margin: 4rem 0;
}
.video-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.video-wrapper iframe {
  max-width: 100%;
  border-radius: 5px;
  width: 100%;
  margin: auto;
}
.video-wrapper h3 {
  font-weight: 500;
  font-size: 6rem;
  line-height: 6.2rem;
  letter-spacing: -0.02em;
  color: #EBEBF0;
}
.video-wrapper > div > p {
  font-weight: 400;
  line-height: 3.1rem;
  color: #CFCFD0;
  margin: 3rem 0 1rem 0;
}
.video-wrapper > div:first-child {
  margin-right: 4rem;
}
.milestone h4 {
  font-weight: 500;
  font-size: 4.8rem;
  line-height: 6rem;
  letter-spacing: -0.02em;
  color: #FFA800;
}
.milestone p {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #EBEBF0;
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .features-wrapper {
    padding: 10rem 1.5rem;
  }
  .features-wrapper h2 {
    font-size: 4.1rem;
    line-height: 4.8rem;
  }
  .features-wrapper .list-wrapper,
  .video-wrapper {
    display: block;
  }
  .video-wrapper h3 {
    font-size: 4.1rem;
    line-height: 5rem;
  }
  .video-wrapper > div > p {
    margin: 2rem 0;
  }
  .video-wrapper > div:nth-child(2) {
    width: 100%;
    position: absolute;
    left: 0;
    margin: 0;
    margin-top: 7rem;
  }
  .milestone h4 {
    font-size: 4.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .features-wrapper {
    padding: 10rem;
  }
  .video-wrapper h3 {
    font-size: 3rem;
    line-height: 4.2rem;
  }
  .video-wrapper > div:nth-child(2) img {
    height: 100%;
  }
}
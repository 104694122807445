.feature-list {
  padding: 2rem 0;
}
.feature-list h5 {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: #EBEBF0;
}
.feature-list p {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #CFCFD0;
}
.feature-list img {
  width: 45px;
}
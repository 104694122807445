.cta-wrapper {
  background: url("../../../assets/images/cta.svg");
  background-size: cover;
  padding: 8rem 6rem;
  margin: 2rem 10rem;
  border-radius: 10px;
}
.cta-wrapper > div {
  width: 50%;
}
.cta-wrapper h4 {
  font-weight: 500;
  font-size: 7.2rem;
  line-height: 6.5rem;
  letter-spacing: -0.02em;
  color: #FFF;
}
.cta-wrapper p {
  width: 75%;
  margin-top: 1rem;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #F8F8FC;
  mix-blend-mode: normal;
}
.cta-wrapper .btn-register {
  margin-top: 2.5rem;
}

@media screen and (max-width: 768px) {
  .cta-wrapper {
    background: url("../../../assets/images/cta-mobile.svg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 8rem 2rem;
    margin: 2rem 0;
    border-radius: 0;
    text-align: center;
  }
  .cta-wrapper > div,
  .cta-wrapper p {
    width: 100%;
  }
  .cta-wrapper h4 {
    font-size: 4.3rem;
    line-height: 4.6rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .cta-wrapper > div {
    width: 70%;
  }
  .cta-wrapper h4 {
    font-size: 6.2rem;
  }
}
@font-face {
  font-family: 'SFProDisplayLightItalic';
  src: url('assets/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYLIGHTITALIC.OTF');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplayBlackItalic';
  src: url('assets/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYBLACKITALIC.OTF');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplayHeavyItalic';
  src: url('assets/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYHEAVYITALIC.OTF');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplayRegular';
  src: url('assets/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.OTF');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplayMedium';
  src: url('assets/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYMEDIUM.OTF');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFProDisplayBold';
  src: url('assets/fonts/sf-pro-display-cufonfonts/SFPRODISPLAYBOLD.OTF');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  font-size: 62.5%;
}
body {
  font-family: 'SFProDisplayRegular', sans-serif;
}
.text-center {
  text-align: center;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.j-center {
  justify-content: center;
}
.j-space-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.btn {
  display: inline-block;
  border-radius: 14px;
  padding: 1rem 4.5rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 24px;
  text-decoration: none;
}
.btn-register {
  background: #F3000B;
  border-radius: 14px;
  color: #fff;
}
.btn-register:hover {
  background: transparent;
  color: #F3000B;
  border: 1px solid #F3000B;
}
h4,
.customer-payment > div > div:last-child p:first-child {
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5.4rem;
  letter-spacing: -0.02em;
  color: #1C1C1E;
}
p {
  font-size: 1.8rem;
}
.no-desktop {
  display: none;
}
.no-mobile {
  display: block;
}
.w-100 {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .no-desktop {
    display: block;
  }
  p {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
  .no-mobile {
    display: none;
  }
  h4 {
    font-size: 3.8rem;
  }
}
@media screen and (min-width: 1920px) {
  body {
    padding: 0 20%;
  }
}
@media screen and (min-width: 3500px) {
  body {
    padding: 0 33%;
  }
}